import React, { useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Button } from 'antd'
import { DIR_URL } from 'shared/api/directories'
import { executeModule, SETTINGS_URL } from 'shared/api/modules'

const CreateChannels: FC<{ id: string }> = ({ id }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { mutate } = useSWRConfig()

  return (
    <Button
      key={'create_channels'}
      type="primary"
      size={'small'}
      loading={isLoading}
      onClick={() => {
        setIsLoading(true)

        executeModule(id, 'create_channels')
          .then(() => {
            mutate(`${SETTINGS_URL}${id}`)
            mutate(DIR_URL)
          })
          .catch((err) => console.error(err))
          .finally(() => setIsLoading(false))
      }}
    >
      Создать директорию и каналы
    </Button>
  )
}

export default CreateChannels
