import React, { useContext } from 'react'
import type { FC } from 'react'
import { Divider, Flex } from 'antd'
import { ModuleContext } from 'layouts/module-layout'
import type { ModuleContextType } from 'layouts/module-layout'
import type { RealTracSettingsType } from '../types'
import RealtracForm from './realtrac-form'
import RealtracFolderForm from './realtrac-folder-form'
import CreateChannels from './create-channels'

const RealTrac: FC = () => {
  const { state: { settings, isChanged }, reducerDispatch } = useContext<ModuleContextType<RealTracSettingsType>>(ModuleContext)

  return (
    <Flex vertical style={{ height: '100%' }}>
      <div className={'content-wrapper bd-bottom'}>
        <CreateChannels id={settings.uid}/>
      </div>

      <div className={'content-wrapper'}>
        <RealtracForm options={{ settings, isChanged, reducerDispatch }} />

        <Divider orientation="left" plain>Структура модуля</Divider>

        <RealtracFolderForm options={{ settings, isChanged, reducerDispatch }} />
      </div>
    </Flex>
  )
}

export default RealTrac
