import React, { useState } from 'react'
import type { FC } from 'react'
import AppDropdownMenu from 'shared/ui/app-dropdown-menu'
import { AboutModal } from 'shared/ui/about-modal'
import { aboutItems, actions } from './config'


export const About: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  return (
    <>
      <AppDropdownMenu
        title={'О программе'}
        items={aboutItems}
        actions={actions}
        onClick={({ key }) => {
          if (key === 'ab-2')
            setIsModalOpen(true)
        }}
      />

      <AboutModal
        isOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  )
}
