import React, { FC } from 'react'
import { useAppSelector } from 'app/store/hooks'
import { ChannelsLayout } from 'layouts/channels-layout'
import { HttpServer } from 'widgets/plugins/http-server'
import { MFSS } from 'widgets/plugins/mfss'
import { ICMP } from 'widgets/plugins/icmp'
import { LuaScript } from 'widgets/plugins/lua-script'
import { OpcUaServer } from 'widgets/plugins/opc-ua-server'
import { OpcUaClient } from 'widgets/plugins/opc-ua-client'
import { ModbusRtu, ModbusTcp } from 'widgets/plugins/modbus'
import { DBPlugin } from 'widgets/plugins/database'
import { License } from 'widgets/license'
import { Wialon } from 'widgets/plugins/wialon'
import { Diwo } from 'widgets/plugins/diwo'
import { JsonParser } from 'widgets/plugins/json-parser'
import { WebSocket } from 'widgets/plugins/web-socket'
import { Visorlabs } from 'widgets/plugins/visorlabs'
import { selectActiveTab } from 'entities/tab'
import { SNMP } from 'widgets/plugins/snmp'
import RealTrac from 'widgets/plugins/real-trac'
import { MQTT } from 'widgets/plugins/mqtt'
import { Notifications } from 'widgets/plugins/notifications'
import WialonRetranslator from 'widgets/plugins/wialon-retranslator'
import type { ContentComponents, ContentProviderProps } from './types'

/*
* Объект с компонентами модулей.
* Обновлять после добавления каждого модуля.
* */
const components: { [K in ContentComponents]: FC<any> } = {
  channelContent: ChannelsLayout,
  license: License,
  http_server: HttpServer,
  icmp: ICMP,
  lua_script: LuaScript,
  opc_ua_server: OpcUaServer,
  opc_ua_client: OpcUaClient,
  modbus_rtu: ModbusRtu,
  modbus_tcp: ModbusTcp,
  postgresql: DBPlugin,
  odbc: DBPlugin,
  wialon: Wialon,
  diwo: Diwo,
  json_parser: JsonParser,
  ws_server: WebSocket,
  mfss_static: MFSS,
  visorlabs: Visorlabs,
  snmp: SNMP,
  realtrac: RealTrac,
  mqtt: MQTT,
  sms_email: Notifications,
  wialon_retranslator: WialonRetranslator,
}

/*
* Провайдер принимает единственную страницу в приложении
* и передает в нее текущий активный компонент для отображения
* */
export const ContentProvider: FC<ContentProviderProps> = ({ children }) => {
  const activeTab = useAppSelector(selectActiveTab)

  return (
    <div className={'content'}>
      {children({
        ActiveComponent: activeTab?.component && components[activeTab?.component]
      })}
    </div>
  )
}
