import React, { useEffect, useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { message } from 'antd'
import { configItems } from './config'
import { useAppSelector } from 'app/store'
import { selectCurrentProject } from 'entities/project'
import { API_URL } from 'shared/config'
import type { MenuInfo } from 'shared/types'
import { exportProject, importProject } from 'shared/api/projects'
import getSHA256String from 'shared/lib/get-SHA256-string'
import fileSaver from 'shared/lib/file-saver'
import ASCIIToUTF8 from 'shared/lib/ASCII-to-UTF-8'
import fileUploader from 'shared/lib/file-uploader'
import AppDropdownMenu from 'shared/ui/app-dropdown-menu'
import useSaveProject from './hooks/useSaveProject'
import OpenProject from './ui/open-project'
import SaveProjectAs from './ui/save-project-as'
import NewProject from './ui/new-project'
import DeleteProjects from './ui/delete-projects'

export const Configuration: FC = () => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isSaveAsModal, setIsSaveAsModal] = useState<boolean>(false)
  const [isNewModal, setIsNewModal] = useState<boolean>(false)
  const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false)
  const { saveProjectHandler, saveMessageState } = useSaveProject()
  const currentProject = useAppSelector(selectCurrentProject)
  const [messageApi, contextHolder] = message.useMessage()
  const { mutate } = useSWRConfig()

  useEffect(() => {
    if (saveMessageState === 'loading') {
      messageApi.open({
        key: 'saveMessage',
        type: 'loading',
        content: 'Сохранение...',
      })
    }

    if (saveMessageState === 'success') {
      messageApi.open({
        key: 'saveMessage',
        type: 'success',
        content: 'Конфигурация сохранена.',
        duration: 5,
        onClick: () => messageApi.destroy('updatable')
      })
    }

    if (saveMessageState === 'destroy')
      messageApi.destroy('updatable')
  }, [saveMessageState])

  const onClickHandler = ({ key } : MenuInfo) => {
    switch (key) {
      case 'conf-1':
        setIsNewModal(true)
        break
      case 'conf-2':
        setIsOpenModal(true)
        break
      case 'conf-3':
        saveProjectHandler(currentProject)
        break
      case 'conf-4':
        setIsSaveAsModal(true)
        break
      case 'conf-5':
        importHandler()
        break
      case 'conf-6':
        exportHandler()
        break
      case 'conf-7':
        setIsDeleteModal(true)
        break
    }
  }

  const exportHandler = async () => {
    try {
      await saveProjectHandler(currentProject, true)
      const res = await exportProject(currentProject)
      const hash = getSHA256String(res.data)

      if (hash === res.hash) {
        fileSaver(ASCIIToUTF8(atob(res.data)), currentProject)
      } else {
        console.error('Hash is not valid')
        message.error({
          content: 'Ошибка экспорта файла',
          key: 'exportError',
          duration: 10,
          onClick: () => message.destroy('exportError')
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const importHandler = () => {
    fileUploader('.dcs3project')
      .then(async (res) => {
        const text = String(res.data)
        const textBytes = new TextEncoder().encode(text)
        const binString = Array.from(textBytes, (byte) =>
          String.fromCodePoint(byte),
        ).join('')
        const textEncoded = btoa(binString)

        if (text) {
          importProject({
            name: res.name,
            hash: getSHA256String(textEncoded),
            data: textEncoded
          })
            .then(() => {
              mutate(`${API_URL}/projects`)

              message.success({
                content: 'Проект загружен!',
                key: 'importProject',
                duration: 5,
                onClick: () => message.destroy('importProject')
              })
            })
            .catch(e => console.error(e))
        } else {
          message.error({
            content: 'Пустой файл',
            key: 'importError',
            duration: 10,
            onClick: () => message.destroy('importError')
          })
        }
      })
  }

  return (
    <>
      {contextHolder}

      <AppDropdownMenu
        title={'Конфигурация'}
        items={configItems}
        onClick={onClickHandler}
      />

      <OpenProject
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />

      <SaveProjectAs
        isOpen={isSaveAsModal}
        onClose={() => setIsSaveAsModal(false)}
      />

      <NewProject
        isOpen={isNewModal}
        onClose={() => setIsNewModal(false)}
      />

      <DeleteProjects
        isOpen={isDeleteModal}
        onClose={() => setIsDeleteModal(false)}
      />
    </>
  )
}
