import React, { useEffect, useState } from 'react'
import type { FC } from 'react'
import { Col, Form, Row, Select } from 'antd'
import { useAppSelector } from 'app/store'
import { setIsChanged, setSettings } from 'layouts/module-layout'
import { BindingChannels } from 'widgets/binding-channels'
import type { ChannelsTableData } from 'entities/channel'
import { selectDirectories } from 'entities/directory'
import { dispatchEvent } from 'shared/lib/custom-events'
import keyToId from 'shared/lib/key-to-id'
import { useChannels } from 'shared/hooks/use-channels'
import { FormChannelBinding } from 'shared/ui/form/form-channel-binding'
import type { RealTracFields, RealtracFormType } from '../types'
import { channelFields } from '../config'

const RealtracFolderForm: FC<RealtracFormType> = ({ options: { settings, reducerDispatch, isChanged } }) => {
  const [channelsArr, setChannelsArr] = useState<string[]>([])
  const [activeBindingField, setActiveBindingField] = useState<string>('')
  const [isBindingActive, setIsBindingActive] = useState<boolean>(false)
  const [isBindingOpen, setIsBindingOpen] = useState<boolean>(false)
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)
  const { data: channelData } = useChannels(channelsArr)
  const directories = useAppSelector(selectDirectories)

  useEffect(() => {
    if (settings) {
      form.setFieldsValue({
        folder_name: settings.folder.name,
        button_press_channel: settings.folder.button_press_channel,
        man_down_channel: settings.folder.man_down_channel,
        free_fall_channel: settings.folder.free_fall_channel,
      })

      const channels = [
        settings.folder.button_press_channel,
        settings.folder.man_down_channel,
        settings.folder.free_fall_channel,
      ].filter(ch => ch)

      if (channels.length) {
        setChannelsArr(Array.from(channels))
      } else {
        setChannelsArr([])
      }
    }
  }, [settings])

  useEffect(() => {
    if (isChanged)
      form.validateFields()
        .then(() => reducerDispatch(setIsChanged(true)))
        .catch(() => reducerDispatch(setIsChanged(false)))
  }, [values])

  useEffect(() => {
    if (channelData && channelData.channels.length) {
      channelData.channels.forEach(ch => {
        if (ch.exist) {
          if (ch.uid === settings.folder.button_press_channel)
            form.setFieldValue('button_press_channel_name', ch.name)

          if (ch.uid === settings.folder.man_down_channel)
            form.setFieldValue('man_down_channel_name', ch.name)

          if (ch.uid === settings.folder.free_fall_channel)
            form.setFieldValue('free_fall_channel_name', ch.name)
        }
      })
    } else {
      form.setFieldValue('button_press_channel_name', '')
      form.setFieldValue('man_down_channel_name', '')
      form.setFieldValue('free_fall_channel_name', '')
    }
  }, [channelData])

  const onSaveBindingHandler = (data: Partial<ChannelsTableData>[]) => {
    if (data.length) {
      form.setFieldValue(activeBindingField, keyToId(String(data[0].key)))
      form.setFieldValue(`${activeBindingField}_name`, data[0].name)

      reducerDispatch(setSettings({
        ...settings,
        folder: {
          ...settings.folder,
          [activeBindingField]: form.getFieldValue(activeBindingField)
        }
      }))
      reducerDispatch(setIsChanged(true))
    }

    setActiveBindingField('')
  }

  return (
    <>
      <Form
        name={'real-trac-folder-form'}
        form={form}
        layout={'vertical'}
        className={'module'}
        onKeyUp={e => {
          if (e.code === 'Enter') dispatchEvent('module:onPressEnter')
        }}
      >
        <Row gutter={[10, 0]} align={'bottom'}>
          <Col span={6}>
            <Form.Item<RealTracFields>
              label="Имя папки"
              name="folder_name"
            >
              <Select
                options={directories?.map(dir => ({
                  label: dir.name,
                  value: dir.uid,
                }))}
                onChange={(val) => {
                  reducerDispatch(setSettings({
                    ...settings,
                    folder: {
                      ...settings.folder,
                      name: val,
                    }
                  }))
                  reducerDispatch(setIsChanged(true))
                }}
              />
            </Form.Item>
          </Col>

          {channelFields.map(item => (
            <Col span={6} key={item.name + '_col'}>
              <FormChannelBinding
                key={item.name + '_field'}
                label={item.label}
                name={item.name}
                compact
                onClick={() => {
                  setIsBindingOpen(true)
                  setIsBindingActive(true)
                  setActiveBindingField(item.name)
                }}
                onClear={() => {
                  reducerDispatch(setSettings({
                    ...settings,
                    folder: {
                      ...settings.folder,
                      [item.name]: ''
                    }
                  }))
                  reducerDispatch(setIsChanged(true))

                  form.setFieldValue(`${item.name}_name`, '')
                }}
              />
            </Col>
          ))}
        </Row>
      </Form>

      {isBindingActive && (
        <BindingChannels
          boundChannels={[]}
          isOpen={isBindingOpen}
          isMultiple={false}
          onSave={onSaveBindingHandler}
          onClose={() => {
            setIsBindingOpen(false)
            setTimeout(() => { setIsBindingActive(false) }, 300)
          }}
        />
      )}
    </>
  )
}

export default RealtracFolderForm
