import type { ObjectsFieldsType } from 'shared/ui/module-objects'
import type { RealTracSettingsType } from './types'

export const channelFields: ObjectsFieldsType<RealTracSettingsType['folder']>[] = [
  {
    label: 'Канал данных о событии по нажатию кнопки',
    name: 'button_press_channel'
  },
  {
    label: 'Канал данных о событии гибели человека',
    name: 'man_down_channel'
  },
  {
    label: 'Канал данных о событии свободного падения',
    name: 'free_fall_channel'
  },
]
