import React, { useEffect, useState } from 'react'
import type { FC } from 'react'
import { Form, Input, Modal } from 'antd'
import { useAppSelector } from 'app/store'
import { selectCurrentProject } from 'entities/project'
import type { ModalPropsType } from '../types'
import useSaveProject from '../hooks/useSaveProject'

const SaveProjectAs: FC<ModalPropsType> = ({ isOpen, onClose }) => {
  const [newProjectName, setNewProjectName] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { saveProjectHandler } = useSaveProject()
  const currentProject = useAppSelector(selectCurrentProject)
  const [form] = Form.useForm()

  useEffect(() => {
    if (currentProject)
      setNewProjectName(currentProject)
  }, [currentProject])

  const onSave = async () => {
    setIsLoading(true)
    await saveProjectHandler(newProjectName)
    setIsLoading(false)
    onClose()
  }

  return (
    <Modal
      title="Сохранить проект как..."
      open={isOpen}
      confirmLoading={isLoading}
      cancelText={'Отмена'}
      okText={'Сохранить'}
      onOk={onSave}
      onCancel={() => {
        form.setFieldValue('projectName', currentProject)
        form.validateFields()
        onClose()
      }}
    >
      <Form
        form={form}
        layout={'vertical'}
        onKeyUp={e => {
          if (e.code === 'Enter') onSave()
        }}
      >
        <Form.Item
          label="Имя проекта"
          name="projectName"
          initialValue={newProjectName}
          rules={[{ required: true, message: 'Введите имя проекта' }]}
        >
          <Input
            onChange={e => setNewProjectName(e.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default SaveProjectAs
