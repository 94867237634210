import React, { useState } from 'react'
import type { FC } from 'react'
import { Modal, Select } from 'antd'
import { useAppSelector } from 'app/store'
import { selectCurrentProject, selectList } from 'entities/project'
import { loadProject } from 'shared/api/projects'
import type { ModalPropsType } from '../types'
import useResetProjectData from '../hooks/useResetProjectData'

const OpenProject: FC<ModalPropsType> = ({ isOpen, onClose }) => {
  const [selectedProject, setSelectedProject] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { resetProjectData } = useResetProjectData()
  const projects = useAppSelector(selectList)
  const currentProject = useAppSelector(selectCurrentProject)

  const loadProjectHandler = () => {
    if (selectedProject) {
      setIsLoading(true)

      loadProject(selectedProject)
        .then((res) => {
          if (res.ok) resetProjectData()
        })
        .catch(err => console.error(err))
        .finally(() => {
          setIsLoading(false)
          onClose()
        })
    } else {
      onClose()
    }
  }

  return (
    <Modal
      title="Открыть проект"
      open={isOpen}
      confirmLoading={isLoading}
      onOk={loadProjectHandler}
      onCancel={() => onClose()}
    >
      <Select
        defaultValue={currentProject}
        style={{ width: '100%' }}
        options={projects.map((prj => ({
          label: prj,
          value: prj,
        })))}
        onChange={(val) => setSelectedProject(val)}
      />
    </Modal>
  )
}

export default OpenProject
