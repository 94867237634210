import { useAppDispatch } from 'app/store'
import { useSWRConfig } from 'swr'
import { resetTabs } from 'entities/tab'
import { setSelectedChannels } from 'entities/channel'
import { setActiveModule, setSelectedPlugin } from 'entities/module'
import { setExpandedDirs, setSelectedDirectory } from 'entities/directory'
import { API_URL } from 'shared/config'
import { DIR_URL } from 'shared/api/directories'
import { getPlugins, getTypes } from 'shared/api/modules'
import { dispatchEvent } from 'shared/lib/custom-events'

const useResetProjectData = () => {
  const dispatch = useAppDispatch()
  const { mutate } = useSWRConfig()

  const resetProjectData = () => {
    mutate(DIR_URL)
    mutate([getTypes, getPlugins])
    mutate(`${API_URL}/modules`)
    mutate(`${API_URL}/projects`)
    mutate(`${API_URL}/info`)
    dispatch(setSelectedPlugin(null))
    dispatch(setSelectedChannels(null))
    dispatch(setActiveModule({ key: '', name: '' }))
    dispatch(setSelectedDirectory({ key: '', title: '' }))
    dispatch(setExpandedDirs(['dir-0']))
    dispatch(resetTabs())
    dispatchEvent('onChangeProject')
  }

  return { resetProjectData }
}

export default useResetProjectData
