import React, { useState } from 'react'
import type { FC } from 'react'
import { useSWRConfig } from 'swr'
import { Modal, Select } from 'antd'
import { useAppSelector } from 'app/store'
import { selectCurrentProject, selectList } from 'entities/project'
import { API_URL } from 'shared/config'
import { deleteProjects } from 'shared/api/projects'
import type { OptionType } from 'shared/types'
import type { ModalPropsType } from '../types'

const DeleteProjects: FC<ModalPropsType> = ({ isOpen, onClose }) => {
  const [projectsToDelete, setProjectsToDelete] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const projects = useAppSelector(selectList)
  const currentProject = useAppSelector(selectCurrentProject)
  const { mutate } = useSWRConfig()

  const deleteHandler = async () => {
    if (projectsToDelete.length) {
      setIsLoading(true)

      try {
        await deleteProjects(projectsToDelete)
        await mutate(`${API_URL}/projects`)

        setProjectsToDelete([])
        onClose()
      } catch (e) {
        console.error(e)
      }

      setIsLoading(false)
    }
  }

  return (
    <Modal
      title="Удаление конфигураций"
      open={isOpen}
      confirmLoading={isLoading}
      cancelText={'Отмена'}
      okText={'Удалить'}
      okButtonProps={{ disabled: !projectsToDelete.length }}
      onOk={deleteHandler}
      onCancel={() => {
        setProjectsToDelete([])
        onClose()
      }}
    >
      <Select
        style={{ width: '100%' }}
        mode={'multiple'}
        value={projectsToDelete}
        onChange={(val) => setProjectsToDelete(val)}
        options={projects.reduce<OptionType[]>((acc, curr) => {
          if (curr !== currentProject) {
            acc.push({
              label: curr,
              value: curr,
            })
          }

          return acc
        }, [])}
      />
    </Modal>
  )
}

export default DeleteProjects
