import React, { useState } from 'react'
import type { FC } from 'react'
import { Form, Input, Modal } from 'antd'
import { loadProject, newProject } from 'shared/api/projects'
import  type { ModalPropsType } from '../types'
import useResetProjectData from '../hooks/useResetProjectData'

const NewProject: FC<ModalPropsType> = ({ isOpen, onClose }) => {
  const [newProjectName, setNewProjectName] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { resetProjectData } = useResetProjectData()
  const [form] = Form.useForm()

  const clearState = () => {
    setNewProjectName('')
    form.resetFields()
    onClose()
  }

  const newProjectHandler = async (name: string) => {
    form.validateFields()

    if (name) {
      setIsLoading(true)

      try {
        await newProject(name)
        const res = await loadProject(name)
        if (res.ok) resetProjectData()
      } catch (e) {
        console.error(e)
      }

      setIsLoading(false)
      clearState()
    }
  }

  return (
    <Modal
      title="Новая конфигурация"
      open={isOpen}
      confirmLoading={isLoading}
      cancelText={'Отмена'}
      okText={'Создать'}
      okButtonProps={{ 'data-testid': 'createButton' }}
      onOk={() => newProjectHandler(newProjectName)}
      onCancel={clearState}
    >
      <Form
        form={form}
        layout={'vertical'}
        onKeyUp={e => {
          if (e.code === 'Enter') newProjectHandler(newProjectName)
        }}
      >
        <Form.Item
          label="Имя проекта"
          name="projectNewName"
          rules={[{ required: true, message: 'Введите имя проекта' }]}
        >
          <Input
            suffix=".dcs3project"
            onChange={e => {
              if (e.target.value)
                setNewProjectName(e.target.value + '.dcs3project')
              else
                setNewProjectName('')
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default NewProject
