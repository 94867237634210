import React, { useEffect } from 'react'
import type { FC } from 'react'
import { Col, Form, Input, Row } from 'antd'
import { setIsChanged, setSettings } from 'layouts/module-layout'
import { dispatchEvent } from 'shared/lib/custom-events'
import filterNums from 'shared/lib/filter-nums'
import type { RealTracFields, RealtracFormType } from '../types'

const RealtracForm: FC<RealtracFormType> = ({ options: { settings, reducerDispatch, isChanged } }) => {
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)

  useEffect(() => {
    if (settings) {
      form.setFieldsValue({
        url: settings.url,
        login: settings.auth_login,
        password: settings.auth_passwd,
        polling_period: settings.polling_alarms_period,
      })
    }
  }, [settings])

  useEffect(() => {
    if (isChanged)
      form.validateFields()
        .then(() => reducerDispatch(setIsChanged(true)))
        .catch(() => reducerDispatch(setIsChanged(false)))
  }, [values])

  return (
    <Form
      name={'real-trac-form'}
      form={form}
      layout={'vertical'}
      className={'module'}
      onKeyUp={e => {
        if (e.code === 'Enter') dispatchEvent('module:onPressEnter')
      }}
    >
      <Row gutter={[10, 0]} align={'bottom'}>
        <Col span={6}>
          <Form.Item<RealTracFields>
            label="Адрес сервера"
            name="url"
            rules={[{ required: true, message: 'Введите адрес сервера' }]}
          >
            <Input
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  url: e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<RealTracFields>
            label="Логин"
            name="login"
            rules={[{ required: true, message: 'Введите логин' }]}
          >
            <Input
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  auth_login: e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<RealTracFields>
            label="Пароль"
            name="password"
            rules={[{ required: true, message: 'Введите пароль' }]}
          >
            <Input
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  auth_passwd: e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item<RealTracFields>
            label="Период опроса"
            name="polling_period"
          >
            <Input
              onKeyPress={filterNums}
              onChange={(e) => {
                reducerDispatch(setSettings({
                  ...settings,
                  polling_alarms_period: +e.target.value
                }))
                reducerDispatch(setIsChanged(true))
              }}
              onBlur={(e) => {
                if (!e.target.value)
                  form.setFieldValue('polling_period', 1)
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default RealtracForm
