import { useState } from 'react'
import { useSWRConfig } from 'swr'
import { API_URL } from 'shared/config'
import { saveProject } from 'shared/api/projects'
import { DIR_URL } from 'shared/api/directories'

const useSaveProject = () => {
  const [saveMessageState, setSaveMessageState] = useState<null | 'loading' | 'success' | 'destroy'>(null)
  const { mutate } = useSWRConfig()

  const saveProjectHandler = async (name: string, silent: boolean = false) => {
    if (!silent) setSaveMessageState('loading')

    try {
      const res = await saveProject(name)

      if (res.ok) {
        mutate(DIR_URL)
        mutate(`${API_URL}/projects`)

        if (!silent) setSaveMessageState('success')
      } else {
        if (!silent) setSaveMessageState('destroy')
      }
    } catch (e) {
      console.error(e)
    }
  }

  return { saveProjectHandler, saveMessageState }
}

export default useSaveProject
